import React from "react";
import Layout from "../../components/Layout/Layout";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
// import logos
import castle from "../../img/white-castle-logo.png";
import jet from "../../img/jetblue-logo-color.png";
import ngp from "../../img/ngp-logo-color.png";
import delta from "../../img/delta-logo-big.png";
import ford from "../../img/ford-logo.png";
import patriots from "../../img/patriots-logo-color.png";
import canaan from "../../img/canaan-logo-big.png";
import legatum from "../../img/logo-legatum.png";
import wrn from "../../img/logo-wrn.png";
import united from "../../img/united-airlines-logo.png";
import via from "../../img/via-logo.png";
import tata from "../../img/tata-logo-big.png";
import clearvision from "../../img/clearvision-logo-big.png";
import syn from "../../img/logo-syndicate.png";
import hangar from "../../img/hangar-logo.png";
import netafim from "../../img/netafim-logo-big.png";
import nesco from "../../img/nesco-logo-color.png";
import fonti from "../../img/logo-fontinalis.png";
import mc from "../../img/logo-mc.png";
import harvard from "../../img/logo-harvard.png";
import usta from "../../img/usta-logo-big.png";

const uasidekick =
  "https://cms2.climacell.co/wp-content/uploads/2019/06/uasidekick-logo.png";
const waycare =
  "https://cms2.climacell.co/wp-content/uploads/2019/07/logo-waycare-color.png";
const mainley =
  "https://cms2.climacell.co/wp-content/uploads/2019/09/mainely-ticks-logo.png";

const hero =
  "https://cms2.climacell.co/wp-content/uploads/2019/08/aviation-hero.jpg";

export default props => (
  <Layout>
    <SEO
      title="カスタマーストーリー | tomorrow.io®"
      pathname={props.location.pathname}
      image={`https://www.tomorrow.io${hero}`}
    />
    <section
      className="c-section-hero"
      style={{
        height: "450px",
        background: `url(${hero}) no-repeat center -300px / cover`,
      }}
    >
      <div className="container h-100">
        <div className="row align-items-center h-100">
          <div className="col-lg-8">
            <div className="p-3 p-sm-5 bg-white" style={{ borderRadius: 4 }}>
              <div className="d-flex align-items-center mb-3">
                <img width={80} src={jet} alt="" />
                <span className="ml-3 fs-lg">カスタマーストーリー</span>
              </div>
              <h1 className="h-2-sm mb-4">
                <Link className="link-inherit" to="/customer-stories/jetblue">
                  jetBlueはtomorrow.ioを導入して、他の人には分からない気象予報が<nobr>把握</nobr>できています
                </Link>
              </h1>
              <Link
                className="btn btn-lg btn-primary"
                to="/customer-stories/jetblue"
              >
                もっと詳しく
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="c-section py-5 bg-light">
      <div className="container">
        <div className="row no-gutters">
          <div className="col-lg-6 p-1">
            <div className="card-square">
              <div className="row">
                <div className="col-sm-6 card-square-divider">
                  <img src={mainley} alt="" />
                </div>
                <div className="col-sm-6 fs-sm">
                  <div>
                  tomorrow.ioは、まったく予期しなかった状況に陥らないよう、一日を周到に準備して迎えるための情報を提供してくれる。
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 p-1">
            <div className="card-square">
              <div className="row">
                <div className="col-sm-6 card-square-divider">
                  <img src={waycare} alt="" />
                </div>
                <div className="col-sm-6 fs-sm">
                  <div>
                    他の気象データ、レベル、頻度などを試してみたが、tomorrow.ioのデータの質は他の何よりも優れており、弊社ニーズに大変有益である。
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 p-1">
            <div className="card-square">
              <div className="row">
                <div className="col-sm-6 card-square-divider">
                  <img src={uasidekick} alt="" />
                </div>
                <div className="col-sm-6 fs-sm">
                  <div>
                    UASidekickの目標は、飛行中の操縦士の安全を確保すること。tomorrow.ioのMicro Weatherソリューションのような、次世代ツールを提供することはUAS産業を前進させるために欠かせないことです。
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*<div className="col-lg-6 p-1">
            <div className="card-square">
              <div className="row">
                <div className="col-sm-6 card-square-divider">
                  <img src={freightwaves} alt="" />
                </div>
                <div className="col-sm-6 fs-sm">
                  <div>
                    Help your freight teams make the right decision in any type
                    of weather: Integrate weather information into your platform
                    — all in one place
                    <br />
                    <br />
                    <Link
                      className="btn btn-sm btn-primary"
                      to="/customer-stories/freightwaves"
                    >
                      もっと詳しく
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>*/}
          <div className="col-lg-6 p-1">
            <div className="card-square">
              <div className="row">
                <div className="col-sm-6 card-square-divider">
                  <img src={castle} alt="" />
                </div>
                <div className="col-sm-6 fs-sm">
                  <div>
                    私たちは企業として、どのように気象を追跡し、ビジネスに活用する、何かいい手があると信じていました。tomorrow.ioはまさにそこに希望の光をもたらしてくれました・・・
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 p-1">
            <div className="card-square">
              <img src={ngp} alt="" />
            </div>
          </div>
          <div className="col-md-6 col-lg-3 p-1">
            <div className="card-square">
              <img src={delta} alt="" />
            </div>
          </div>
          <div className="col-lg-6 p-1">
            <div className="card-square">
              <div className="row">
                <div className="col-sm-6 card-square-divider">
                  <img src={ford} alt="" />
                </div>
                <div className="col-sm-6 fs-sm">
                  <div>
                  tomorrow.ioと戦略的な関係性を築いたことは弊社ビジネス全体に大変な価値をもたらしてくれました・・・将来的には、リアルタイムのデータは、無人自動車が悪天候を回避することができるように役立つでしょう。
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 p-1">
            <div className="card-square">
              <div className="row">
                <div className="col-sm-6 card-square-divider">
                  <img src={patriots} alt="" />
                </div>
                <div className="col-sm-6 fs-sm">
                  <div>
                    もし雷が土地に落ちる可能性がある場合、皆を避難場所へ誘導するためにも、その危険性を事前に把握する必要があります。tomorrow.ioのツールはいつそのような状況が発生するのかと、過ぎ去るのかを教えてくれます。
                    <br />
                    <br />
                    <Link
                      className="btn btn-sm btn-primary"
                      to="/customer-stories/gillette-stadium-and-the-new-england-patriots"
                    >
                      もっと詳しく
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 p-1">
            <div className="card-square">
              <img src={canaan} alt="" />
            </div>
          </div>
          <div className="col-md-6 col-lg-3 p-1">
            <div className="card-square">
              <img src={legatum} alt="" />
            </div>
          </div>
          <div className="col-md-6 col-lg-3 p-1">
            <div className="card-square">
              <img src={wrn} alt="" />
            </div>
          </div>
          <div className="col-md-6 col-lg-3 p-1">
            <div className="card-square">
              <img src={united} alt="" />
            </div>
          </div>
          <div className="col-lg-6 p-1">
            <div className="card-square">
              <div className="row">
                <div className="col-sm-6 card-square-divider">
                  <img src={via} alt="" />
                </div>
                <div className="col-sm-6 fs-sm">
                  <div>
                  tomorrow.ioの予報は、正確で、リアルタイムの実用的な気づきを提供してくれます。tomorrow.ioのプロダクトに大変感心し、私たちのオペレーション意思決定や、顧客に質の高いサービスを提供するのに役立っています。
                    <br />
                    <br />
                    <Link
                      className="btn btn-sm btn-primary"
                      to="/customer-stories/via-microweather-case-study"
                    >
                      もっと詳しく
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 p-1">
            <div className="card-square">
              <div className="row">
                <div className="col-sm-6 card-square-divider">
                  <img src={tata} alt="" />
                </div>
                <div className="col-sm-6 fs-sm">
                  <div>
                    役立つ実例として・・・作物保険があります。かなりの低コストでありながら、正確で、リアルタイムの一貫したフィールドレベルのデジタルデータ、そして物理的なインフラ整備の必要を削除してくれます。
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 p-1">
            <div className="card-square">
              <img src={clearvision} alt="" />
            </div>
          </div>
          <div className="col-md-6 col-lg-3 p-1">
            <div className="card-square">
              <img src={syn} alt="" />
            </div>
          </div>
          <div className="col-md-6 col-lg-3 p-1">
            <div className="card-square">
              <img src={hangar} alt="" />
            </div>
          </div>
          <div className="col-md-6 col-lg-3 p-1">
            <div className="card-square">
              <img src={fonti} alt="" />
            </div>
          </div>
          <div className="col-lg-6 p-1">
            <div className="card-square">
              <div className="row">
                <div className="col-sm-6 card-square-divider">
                  <img src={netafim} alt="" />
                </div>
                <div className="col-sm-6 fs-sm">
                  <div>
                    NetBeat™は、tomorrow.ioのようなスマートなソリューションと統合できるよう、オープン・プラットフォームとして構築されました。正確な気象データに、正確な灌漑を組み合わせることで、リソースの利用を最適化でき、栽培者に高い価値をもたらします。
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 p-1">
            <div className="card-square">
              <div className="row">
                <div className="col-sm-6 card-square-divider">
                  <img src={nesco} alt="" />
                </div>
                <div className="col-sm-6 fs-sm">
                  <div>
                    このソリューションを活用する事によって、確実にコスト削減とスタッフの時間短縮ができました。このソリューションを他の舗装業者や建設企業にお勧めしています。
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 p-1">
            <div className="card-square">
              <img src={mc} alt="" />
            </div>
          </div>
          <div className="col-md-6 col-lg-3 p-1">
            <div className="card-square">
              <img src={harvard} alt="" />
            </div>
          </div>
          <div className="col-lg-6 p-1">
            <div className="card-square">
              <div className="row">
                <div className="col-sm-6 card-square-divider">
                  <img src={usta} alt="" />
                </div>
                <div className="col-sm-6 fs-sm">
                  <div>
                    NTCが、とらえどころのないデータといえる気象データの利用において、MicroWeather予報で可能なことを紹介してくれました。
                    <br />
                    <br />
                    <Link
                      className="btn btn-sm btn-primary"
                      to="/customer-stories/usta-microweather-case-study"
                    >
                      もっと詳しく
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="text-center pt-5">
          <a className="btn btn-lg btn-primary" href="https://Tomorrow.io/free-trial?bid=2">
            弊社の気象専門家にビジネスについて相談
          </a>
        </div>
      </div>
    </section>
  </Layout>
);
